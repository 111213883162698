import React from 'react';
import loadable from '@loadable/component';
import { Layout } from '../modules/ui';

const UIkit = loadable(() => import('../modules/UIkit'));

const UIkitPage = () => {
    return (
        <Layout>
            <UIkit />
        </Layout>
    );
};

export default UIkitPage;
